<template>
  <div class="gps-calendar" name="GpsCalendar">
    <!-- 年份/月份 流量查询-->
    <div class="monthHeader">
      <!--绑定click事件，点击按钮；重新刷新当前日期-->
      <button
        class="lf oprButton oprButton-bg ml5"
        @click="pickPre(currentYear, currentMonth)"
      >
        ❮{{ $t("map.shangyue") }}
      </button>
      <span class="lf oprButton title-data"
        >{{ nowFullYear }}/{{ nowMonth }}/{{ nowDay }}</span
      >
      <button
        class="lf oprButton oprButton-bg"
        @click="pickNext(currentYear, currentMonth)"
      >
        {{ $t("map.xiayue") }}❯
      </button>
      <button
        class="lf oprButton oprButton-bg ml5"
        @click="pickToday(currentYear, currentMonth)"
      >
        {{ $t("map.jintian") }}
      </button>
    </div>
    <!-- 日历 -->
    <div class="calendar-list calendar-date">
      <!-- 星期 -->
      <ul class="calendar-weekadys clearfix">
        <li class="weekadys-item">{{ $t("map.yi") }}</li>
        <li class="weekadys-item">{{ $t("map.er") }}</li>
        <li class="weekadys-item">{{ $t("map.san") }}</li>
        <li class="weekadys-item">{{ $t("map.si") }}</li>
        <li class="weekadys-item">{{ $t("map.wu") }}</li>
        <li class="weekadys-item">{{ $t("map.liu") }}</li>
        <li class="weekadys-item">{{ $t("map.ri") }}</li>
      </ul>
      <!-- 日期 -->
      <ul class="calendar-days clearfix">
        <!-- 核心 v-for循环 每一次循环用<li>标签创建一天 -->
        <li
          class="daysList"
          v-for="(dayobject, inedx) in days"
          :key="dayobject.id"
        >
          <!--本月-->
          <!--如果不是本月 改变类名加灰色-->
          <div
            class="daysList-cont daysList-invalid"
            v-if="dayobject.day.getMonth() + 1 != currentMonth"
          >
            <div class="daysList-mid">
              <p class="daysList-item">{{ dayobject.day.getDate() }}</p>
              <p class="daysList-item item-line" v-if="dayobject.is_show"></p>
            </div>
          </div>
          <!-- 如果是本月 判断是不是该月第一天-->
          <div
            class="daysList-cont daysList-normal"
            :class="{ active: inedx == number }"
            v-else-if="
              (dayobject.day.getFullYear() == currentYear && //当前年份
                dayobject.day.getMonth() + 1 == currentMonth && //本月且不是系统月份
                dayobject.day.getMonth() != new Date().getMonth() &&
                dayobject.day.getDate() == currentDay) ||
              (dayobject.day.getMonth() == new Date().getMonth() && //当前系统时间
                dayobject.day.getDate() == new Date().getDate())
            "
            @click="
              pickDays(
                currentYear,
                currentMonth,
                dayobject.day.getDate(),
                inedx
              )
            "
            @dblclick="pickDbDays(dayobject)"
          >
            <div class="daysList-mid">
              <p class="daysList-item">{{ dayobject.day.getDate() }}</p>
              <p class="daysList-item item-line" v-if="dayobject.is_show"></p>
            </div>
          </div>
          <!-- 如果是本月-->
          <div
            class="daysList-cont daysList-normal"
            v-else
            :class="{ active: inedx == number }"
            @click="
              pickDays(
                currentYear,
                currentMonth,
                dayobject.day.getDate(),
                inedx
              )
            "
            @dblclick="pickDbDays(dayobject)"
          >
            <div class="daysList-mid">
              <p class="daysList-item">
                {{ dayobject.day.getDate() }}
              </p>
              <p class="item-line" v-if="dayobject.is_show"></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "GpsCalendar",
  emits: ["select"],
  props: {
    selectDates: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    selectDates(val) {
      this.$nextTick(() => {
        this.days.forEach((it) => {
          it.is_show = false;
          if (this.in_array(val, it.str_day)) {
            it.is_show = true;
          }
        });
      });
    },
  },
  data() {
    return {
      number: 0, //active样式索引
      currentDay: 1, //当前日
      currentMonth: 1, //当前月份
      currentYear: 1970, //当前年份
      currentWeek: 1, //前星期X
      nowFullYear: 1970, //中间显示当前年
      nowMonth: 1, //中间显示当前月
      nowDay: 1, //中间显示当前日
      days: [],
    };
  },
  created() {
    //在vue初始化时调用
    this.initData(null);
    this.number = 0;
  },
  methods: {
    getCurrMonth() {
      let str =
        this.currentMonth > 9
          ? this.currentMonth
          : "0" + this.currentMonth.toString();
      return this.currentYear + "-" + str;
    },
    initData(cur) {
      let date;
      if (cur) {
        date = new Date(cur);
      } else {
        const now = new Date();
        const d = new Date(
          this.formatDate(now.getFullYear(), now.getMonth(), 1)
        );
        d.setDate(35);
        date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }
      // 初始化年月日
      this.currentDay = date.getDate();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;

      this.nowDay = new Date().getDate();
      this.nowFullYear = date.getFullYear();
      this.nowMonth = date.getMonth() + 1;

      this.currentWeek = date.getDay(); //获取当前星期X(0-6,0代表星期天)
      if (this.currentWeek == 0) {
        this.currentWeek = 7;
      }
      const str = this.formatDate(
        this.currentYear,
        this.currentMonth,
        this.currentDay
      );
      this.days.length = 0;
      // 例今天是周五，放在第一行第5个位置，前面4个上个月的
      //初始化本周
      for (let i = this.currentWeek - 1; i >= 0; i--) {
        const d = new Date(str);
        d.setDate(d.getDate() - i);
        const dayobject = {}; //用一个对象包装Date对象 以便为以后预定功能添加属性
        dayobject.day = d;
        dayobject.str_day = this.dateFormat("YYYY-mm-dd", d);
        dayobject.is_show = false;
        dayobject.parccent = "100%";
        this.days.push(dayobject); //将日期放入data 中的days数组 供页面渲染使用
      }

      //this.nowDay-1（今天几号索引）this.currentWeek-1（当月第一天周几索引）
      //得到今天的索引值 初始化active样式
      this.number = this.nowDay + this.currentWeek - 2;

      //列表显示的天数6*7减去前星期X
      for (let i = 1; i <= 42 - this.currentWeek; i++) {
        const d = new Date(str);
        d.setDate(d.getDate() + i);
        const dayobject = {};
        dayobject.day = d;
        dayobject.str_day = this.dateFormat("YYYY-mm-dd", d);
        dayobject.is_show = false;
        dayobject.parccent = "100%";
        this.days.push(dayobject);
      }
      // console.log(this.days);
    },
    pickDbDays(item) {
      // console.log("item", item);
      this.$emit("select", item);
    },
    //上个月
    pickPre(year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      const d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      this.nowDay = 1;
      this.number = this.currentWeek - 1; //active样式
    },
    //下个月
    pickNext(year, month) {
      const d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      this.nowDay = 1;
      // console.log(this.currentWeek)
      this.number = this.currentWeek - 1; //active样式
      // console.log(this.number)
      //console.log(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1))
    },
    //今天
    pickToday(year, month) {
      const d = new Date();
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      //this.number=this.currentWeek-1;//active样式
      //console.log(this.formatDate(d.getFullYear(), d.getMonth()+1, d.getDate()))
    },
    pickYear(year, month) {
      //alert(year + "," + month);
    },
    //当前日历时间点击
    pickDays(year, month, clickCurrentDay, index) {
      const d = new Date();
      const day = clickCurrentDay;
      //active样式的更改
      this.number = index;
      // 年月日更改
      this.nowFullYear = year;
      this.nowMonth = month;
      this.nowDay = clickCurrentDay;
      // this.$emit("select");
      // console.log(year,month,clickCurrentDay);
    },
    // 格式化日期
    formatDate(year, month, day) {
      let y = year;
      let m = month;
      if (m < 10) m = "0" + m;
      let d = day;
      if (d < 10) d = "0" + d;
      return y + "/" + m + "/" + d;
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (const k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    in_array(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i] === obj) return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../common/css/common.scss";
$bg-color: #fafafa;
//定义基本长度
$line10: 10px;
$lf: left;
$rt: right;

$color-fff: #ffffff;
//按钮背景颜色
$button-bg: #0078f3;
//正文颜色
$text-color: #333333;
// $text-color: #6d7882;
//hover蓝色
$table-deepBlue: #0c8ceb;
$table-blue: #289cf4;
//失效颜色
$invalid-color: #2f3f53;
// $item-color: #b8c9da;
$item-invalid-color: #77899c;
$item-color: #333333;
//字体居中
$text-center: center;
button {
  outline: none;
  border: none;
}
div,
ul,
li {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
.lf {
  float: $lf;
}
.rt {
  float: $rt;
}
.topWrap {
  height: $line10 * 33.5;
}
//清楚浮动
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
}
p {
  margin: 0;
  padding: 0;
}
//日历表头按钮
.gps-calendar {
  width: 100%;
  height: 100%;
  background: $bg-color;
}
//日历表头
.monthHeader {
  height: $line10 * 3.6;
  padding-top: $line10 * 0.6;
  //按钮样式
  .oprButton {
    height: $line10 * 2.5;
    padding: 0 $line10;
    border-radius: $line10 * 0.5;
    color: $color-fff;
    line-height: $line10 * 2.5;
    cursor: pointer;
  }
  .oprButton-bg {
    background-color: $button-bg;
  }
  .title-data {
    text-align: $text-center;
    width: $line10 * 10;
    color: #6d7882;
  }
  .ml5 {
    margin-left: $line10/2;
  }
  .mr10 {
    margin-right: $line10;
  }
}

//日历
.calendar-list {
  color: $text-color;
  //日历星期头
  .calendar-weekadys {
    width: 100%;
  }
  .calendar-weekadys .weekadys-item {
    height: $line10 * 2.4;
    line-height: $line10 * 2.4;
    color: #695e79;
  }
  .calendar-weekadys .weekadys-item,
  .calendar-days .daysList {
    width: 14%;
    float: $lf;
    text-align: $text-center;
    color: $text-color;
    // margin-right: $line10 * 0.1;
    box-sizing: border-box;
  }
  .calendar-days .daysList {
    cursor: pointer;
    height: $line10 * 4.5;
    // line-height: $line10 * 4.5;
    color: $item-color;
    .daysList-cont {
      width: 100%;
      float: $lf;
    }
  }
  .calendar-days .daysList-normal .daysList-item:nth-child(2n) {
    color: $item-invalid-color;
  }
  .calendar-days .daysList-normal .daysList-item:nth-child(2n + 1) {
    color: $item-color;
  }
  .calendar-days .daysList-mid {
    height: $line10 * 2;
    margin: $line10 * 0.4;
    display: flex;
    flex-direction: column;
  }
  .calendar-days .daysList-cont.daysList-normal:hover,
  .daysList-normal.active {
    border-radius: $line10/2;
    background-color: $table-deepBlue;
  }
  .calendar-days .daysList-cont.daysList-normal:hover .daysList-mid,
  .daysList-normal.active .daysList-mid {
    background-color: $table-blue;
  }
  .calendar-days .daysList-cont.daysList-normal:hover p,
  .daysList-normal.active p {
    color: $color-fff !important;
  }
  .daysList-item {
    height: $line10 * 1.6;
    line-height: $line10 * 1.6;
  }
  .item-line {
    // border-top: 2px solid #0078f3;
    position: relative;
    // width: 50%;
  }
  .item-line::after {
    position: absolute;
    content: " ";
    width: 50%;
    height: 2px;
    background: #0078f3;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  // 上个月或者下个月
  .daysList-invalid {
    // background-color: $invalid-color;
    border-radius: $line10/2;
  }
  .daysList-invalid .daysList-item {
    color: $item-invalid-color;
  }
}
</style>
