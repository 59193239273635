<template>
  <splitpanes class="default-theme dth" @resize="paneSize = $event[0].size">
    <pane :size="paneSize">
      <div class="left">
        <div class="left-1">
          <div class="flex search">
            <el-select v-model="value_st" :placeholder="$t('map.qingxuanze')">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input
              clearable
              v-model="query_key"
              :placeholder="$t('map.qingshuruchaxunguanjianzi')"             
              @input="onQueryChanged"
            />
          </div>

          <el-tree-v2
            ref="treeRef"
            :data="treeData"
            :height="maxheight"
            :props="propoption"
            default-expand-all
            :default-expanded-keys="defaultExpandedKeys"
            :expand-on-click-node="false"
            :filter-method="filterMethod"
            ><template #default="{ node, data }">
              <span class="prefix"
                ><img :src="data.icon" alt="" style="width: 17px; height: 17px"
              /></span>
              <span @dblclick="nodeClick(data)" style="color: #333">{{
                node.label
              }}</span>
            </template></el-tree-v2
          >
        </div>
        <div class="sjb">
          <GpsCalendar
            ref="gpsCalender"
            :select-dates="selectDates"
            @select="select_date_fun"
          ></GpsCalendar>
        </div>
      </div>
    </pane>
    <pane :size="100 - paneSize" style="padding-right: 5px">
      <div :style="{ height: 'calc(100% - ' + mapBottomHeight + 'px)' }">
        <!-- <LeafletMap ref="mainMap"></LeafletMap> -->
        <GpsMap
          ref="historyMap"
          style="border: 1px solid #ccc; margin-top: 5px"
        >
        </GpsMap>
      </div>
      <div class="map-bottom" :style="{ height: mapBottomHeight + 'px' }">
        <div class="close" @click="closeMsg"></div>
        <el-table
          :data="dataHistory"
          border
          stripe
          :height="mapBottomHeight - 10"
          @row-click="onRowClick"
          style="border: 1px solid #ccc; border-top: none"
          :row-class-name="tableRowClass"
        >
          <el-table-column
            show-overflow-tooltip
            align="center"
            v-for="col in columns"
            :prop="col.field"
            :key="col.field"
            :label="col.label"
            :width="col.width"
          >
            <template #default="{ row }">
              <span
                v-if="col.render && typeof col.render == 'function'"
                v-html="col.render(row)"
              ></span>
              <span v-else>{{ row[col.field] }}</span>
            </template>
          </el-table-column>
        </el-table>

        <!-- {{ $t("map.rImg") }} -->
      </div>

      <!-- <MapComponent></MapComponent> -->
      <!-- <div id="allmap"></div> -->
    </pane>
  </splitpanes>
</template>

<script>
import { fa } from "element-plus/es/locale";
import { Microphone } from "@element-plus/icons-vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
// import MapComponent from "../components/map/MapComponent.vue";
// import LeafletMap from "../components/LeafletMap.vue";
import GpsMap from "../components/GpsMapHistory.vue";
import GpsCalendar from "../components/GpsCalendar.vue";
export default {
  components: { Splitpanes, Pane, GpsCalendar, GpsMap },
  data() {
    return {
      value_st: "",
      options: [],
      treeDataTemp: [],
      dataHistory: [],
      maxheight: window.innerHeight - 366,
      tree_node: null,
      columns: [
        { field: "cph", label: this.$t("map.cph_name"), width: "100px" },
        { field: "pGpsTime", label: this.$t("map.track_sj"), width: "200px" },
        {
          field: "pSpeed",
          label: this.$t("map.track_sd"),
          width: "100px",
        },
        { field: "pMileage", label: this.$t("map.track_dslc"), width: "200px" },
        { field: "latlng", label: this.$t("map.track_wz"), width: "100px" },
        {
          field: "pStatus",
          label: this.$t("map.track_zt"),
          width: "200px",
          render: (row) => {
            if (row && row["pStatus"]) {
              var str = row["pStatus"];
              var str_2 = parseInt(str).toString(2);

              var ay = str_2.split("");
              ay.reverse();
              // console.log(this.$t("map.s_1.s0"));
              let msg = this.$t("map.s_1.s" + ay[0]);
              msg += this.$t("map.gps_" + row["islatlng"]); //lang["gps_" + row["islatlng"]];
              //this.$t("map.yx")
              if (row["pNetType"] >= 0) {
                msg +=
                  "," +
                  [
                    this.$t("map.yx"),
                    "4G",
                    "WIFI",
                    "5G",
                    "3G",
                    "2G",
                    this.$t("map.wz"),
                  ][row["pNetType"]];
              }

              var xh = this.$t("map.yxh");
              if (
                row["pWireNetSignValue"] > 0 &&
                row["pWireNetSignValue"] <= 13
              ) {
                xh = this.$t("map.xhc");
              }
              if (
                row["pWireNetSignValue"] > 13 &&
                row["pWireNetSignValue"] <= 20
              ) {
                xh = this.$t("map.xhyb");
              }
              if (row["pWireNetSignValue"] > 20) {
                xh = this.$t("map.xhh");
              }
              msg += "," + xh;

              if (row["pGNSSStarCount"] > 0) {
                msg += "," + this.$t("map.wx") + "：" + row["pGNSSStarCount"];
              }

              if (row["pRecordStatus"] != "" && row["pRecordStatus"] != null) {
                var str_str = row["pRecordStatus"];
                var str_ay = str_str.split(",");
                var result_ay = [];
                for (var i = 0; i < str_ay.length; i++) {
                  if (str_ay[i] != "") {
                    result_ay.push("CH" + str_ay[i]);
                  }
                }
                msg += "," + result_ay.join(",");
              }

              var car_zt = row["pExtStatus"];
              var car_zt_2 = parseInt(car_zt).toString(2);
              var car_ay = car_zt_2.split("");
              car_ay.reverse();

              if (car_ay[2] == 1) {
                msg += "," + this.$t("map.yz");
              }
              if (car_ay[3] == 1) {
                msg += "," + this.$t("map.zz");
              }
              if (car_ay[4] == 1) {
                msg += "," + this.$t("map.cc");
              }
              if (car_ay[5] == 1) {
                msg += "," + this.$t("map.dc");
              }
              return msg;
            } else {
              return "";
            }
            // pos = msg;
          },
        },
        {
          field: "pAlarmMsg",
          label: this.$t("map.track_bj"),
          render: (row) => {
            // console.log(row);
            //101,103
            if (row && row["pAlarmMsg"]) {
              var ay_str = row["pAlarmMsg"];

              var ay_ay = ay_str.split(",");
              let result_ay = [];

              var ay_result = [];
              var str_temp = "";
              var str_a, ay_a, j;
              var str_str, str_ay;
              for (var i = 0; i < ay_ay.length; i++) {
                if (ay_ay[i] >= 217 && ay_ay[i] <= 224) continue;
                if (ay_ay[i] == "") continue;
                str_temp = this.$t("map.e_" + ay_ay[i]);
                if (ay_ay[i] == 101) {
                  str_a = row["pVideoLossStatus"];
                  ay_a = str_a.split(",");
                  for (j = 0; j < ay_a.length; j++) {
                    ay_a[j] = "CH" + ay_a[j];
                  }
                  str_temp += ay_a.join(",");
                } else if (ay_ay[i] == 103) {
                  str_str = row["pVideoMemoryFailureStatus"];
                  str_ay = str_str.split(",");
                  result_ay = [];
                  for (var j = 0; j < str_ay.length; j++) {
                    if (str_ay[j] != "") {
                      if (str_ay[j] == 1) {
                        result_ay.push("HDD");
                      } else {
                        if (str_ay[j] == 2) {
                          result_ay.push("SD");
                        } else {
                          result_ay.push("SD" + (parseInt(str_ay[j]) - 1));
                        }
                      }
                    }
                  }
                  str_temp += result_ay.join(",");
                } else if (ay_ay[i] == 102) {
                  str_a = row["pVideoBlockingStatus"];
                  ay_a = str_a.split(",");
                  for (j = 0; j < ay_a.length; j++) {
                    ay_a[j] = "CH" + ay_a[j];
                  }
                  str_temp += ay_a.join(",");
                }

                ay_result.push(str_temp);
              }

              //不录像
              var str_blx = row["pRecordState"];
              if (str_blx != "") {
                var str_temp_blx = this.$t("map.blx") + ",";
                var ay_blx = str_blx.split(",");
                for (var i = 0; i < ay_blx.length; i++) {
                  ay_blx[i] = "CH" + ay_blx[i];
                }
                str_temp_blx += ay_blx.join(",");
                ay_result.push(str_temp_blx);
              }

              var str_output = ay_result.join(",");

              str_output = str_output.replace(/(^[\s,]*)|([\s,]*$)/g, "");

              return '<span class="tree_red">' + str_output + "</span>";
            } else {
              return "";
            }
            // return this.$t("map.e_" + row.pAlarmMsg);
          },
        },
      ],
      mapBottomHeight: 200,
      selectDates: [],
      sel_date: "",
      paneSize: 17,
      csszProps: {
        children: "children",
        label: "name",
      },
      curr_tree_node: { id: 0 },
      propoption: {
        icon: "icon",
        value: "gps_id",
        label: "nodeName",
        children: "children",
      },
      treeData: [],
      query_key: "",
      cheigth: 600,
      map: null,
      BMap: null,
    };
  },
  created() {
    this.defaultExpandedKeys = ["a_0"];
    this.$api.tree_deptTree_Tree({}).then((res) => {
      this.treeDataTemp = res.data.resultData;
      this.defaultExpandedKeys[0] = this.treeDataTemp[0].value;
      this.delChannel(this.treeDataTemp);
      this.treeData = this.treeDataTemp;
      // this.getPid(this.treeData);
    });
  },
  mounted() {
    // this.init_map();
  },
  methods: {
    tableRowClass({ row }) {
      if (
        row.pVideoLossStatus != "" ||
        row.pAlarmMsg != "" ||
        row.pVideoMemoryFailureStatus != "" ||
        row.pVideoBlockingStatus != "" ||
        row.pRecordState != ""
      ) {
        return "row-red";
      }
      return "";
    },
    delChannel(dataList) {
      dataList.forEach((it) => {
        if (it.nodetype == 2) {
          it.children = [];
        } else {
          if (it.children && it.children.length > 0) {
            this.delChannel(it.children);
          }
        }
      });
    },
    nodeClick(node) {
      this.tree_node = node;
      console.log(this.tree_node);
      // console.log(node, this.$refs.gpsCalender.getCurrMonth());
      this.$api
        .search_get_devices_state({
          deviceID: node.vDeviceID,
          date: this.$refs.gpsCalender.getCurrMonth(),
        })
        .then((res) => {
          let ay = [];
          if (res.data.data) {
            res.data.data.forEach((it) => {
              ay.push(it.dDate);
            });
          }
          this.selectDates = ay;
        });
    },
    onRowClick(row) {
      this.$refs.historyMap.posCar({
        lat: parseFloat(row.pLatitude),
        lng: parseFloat(row.pLongitude),
        time: row.pGpsTime,
        ang: parseFloat(row.pDirection),
        cph: row.cph,
      });
    },
    select_date_fun(item) {
      this.$api
        .search_track_grid({
          page: 1,
          rp: 500,
          deviceid: this.tree_node.vDeviceID,
          sdate: item.str_day + " 00:00:00",
          edate: item.str_day + " 23:59:59",
          cph: this.tree_node.vPlate,
        })
        .then((res) => {
          this.dataHistory = res.data.data;
          //地图经纬度
          let ay = [];
          this.dataHistory.forEach((it) => {
            if (it.pLatitude != 0 && it.pLongitude != 0)
              // ay.push([parseFloat(it.pLatitude), parseFloat(it.pLongitude)]);
              ay.push({
                lat: parseFloat(it.pLatitude),
                lng: parseFloat(it.pLongitude),
                time: it.pGpsTime,
                ang: parseFloat(it.pDirection),
                cph: it.cph,
              });
          });
          this.$refs.historyMap.drawCarRoad(ay);
          // console.log(ay);
        });
    },
    filterMethod(query, node) {
      return node.nodeName.includes(query);
    },
    onQueryChanged(query) {
      this.$refs.treeRef.filter(query);
    },
    // init_map() {
    //   this.BMap = window.BMap;
    //   this.map = new this.BMap.Map("allmap");
    //   var point = new this.BMap.Point(115.487135, 38.873433);
    //   this.map.centerAndZoom(point, 13);
    //   this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    //   // this.map.setMapStyle({ style: "midnight" });
    // },
  },
};
</script>

<style lang="scss" scoped>
#allmap {
  width: 100%;
  height: 100%;
}
::v-deep(.el-tree-node__content) {
  line-height: 26px;
  font-size: 14px;
}
.left {
  box-sizing: border-box;
  padding: 5px 0px 5px 5px;
  border-right: 1px solid #ccc;
}
.left-1 {
  border: 1px solid #ccc;
  border-bottom: none;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.search {
  padding: 5px 10px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.search .el-select {
  margin-right: 5px;
}
.el-tree {
  padding-top: 5px;
}
.el-icon {
  margin-right: 2px;
}
.el-drawer__title {
  font-size: 15px;
  color: #fff;
}
::v-deep .mapbox .tool {
  font-size: 14px;
  padding: 0px 5px;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 7px;
}
::v-deep .el-drawer__title {
  font-size: 14px !important;
  color: #333;
  border-left: 3px solid #2c78bf;
  padding-left: 5px;
}
::v-deep .el-tree-node__content span {
  margin-left: 5px;
}
::v-deep .calendar-list .calendar-days .daysList {
  height: 30px;
}
::v-deep .gps-calendar {
  border: 1px solid #eee;
  margin: 0px 0px;
}
.sjb {
  padding: 5px;
  border: 1px solid #ccc;
  border-top: none;
  background: #fff;
  height: 260px;
}
::v-deep .monthHeader {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
</style>
